

















































































































import { IAccount, IPagination } from '@/types/interfaces';
import { Component, Vue, Watch } from "vue-property-decorator";
import { ActionMethod, mapGetters } from 'vuex';
import { Action, State, Getter } from 'vuex-class';
import SearchProduct from '@/components/product/Search.vue'
import ShopifyProduct from '@/models/ShopifyProduct';
import RedProduct from '@/models/RedProduct';
import VClamp from 'vue-clamp'
import { IError } from '@/types/interfaces';
import EmptyState from '@/components/templates/EmptyState.vue'
const debounce = require('lodash/debounce');

@Component({
  components: { SearchProduct, VClamp, EmptyState }
})
export default class ProductsPage extends Vue {
  private loading: Boolean = true
  private searching: Boolean = false
  private pageSize: number = 10
  private currentPage: number = 1
  private q: string | null = null
  private clearing: Boolean = false
  private showSearch: Boolean = false
  private doneQ: string | null = null
  private synchronizationModal: Boolean = false
  private filter: { status: string | null, cities: [] } = { status: null, cities: [] }

  @State(state => state.shopify.account) account: IAccount;
  @State(state => state.redProducts) products: {items: RedProduct[], count: number, productsToMatch: number};

  @Getter('context/getConsumerName') consumerName: string;

  @Action('fetchAll', { namespace: 'redProducts' }) fetchAll: ActionMethod;
  @Action('searchAll', { namespace: 'redProducts' }) searchAll: ActionMethod;
  @Action('match', { namespace: 'redProducts' }) match: ActionMethod;
  @Action('unmatch', { namespace: 'redProducts' }) unmatch: ActionMethod;
  @Action('fetchCount', { namespace: 'redProducts' }) fetchCount: ActionMethod;
  @Action('synchronizeProducts', { namespace: 'redProducts' }) synchronizeProducts: ActionMethod;

  async mounted() {
    if(this.account && this.account.consumer && this.account.consumer.accessToken) {
      this.initPage()
    } else {
      this.loading = false
    }
  }

  get statuses() {
    return [
      { label: 'Matched', value: 'matched' },
      { label: 'Unmatched', value: 'unmatched' }
    ]
  }

  // get warehouses() {
  //   return [
  //     { label: 'None', value: 'none' },
  //     { label: 'Cainiao', value: 'cainiao' }
  //   ]
  // }

  // get cities() {
  //   return [
  //     { label: 'New York', value: 'ny' },
  //     { label: 'Tokyo', value: 'tk' },
  //     { label: 'Shanghai', value: 'sh' }
  //   ]
  // }

  async fetchObjects() {
    try {
      let pagination:IPagination = { page: this.currentPage, pageSize: this.pageSize }
      let isMatched = this.filter.status == 'unmatched' ? false : this.filter.status == 'matched' ? true : null
      if(this.q && this.q.length > 0) {
        this.showSearch = true
        this.doneQ = this.q
        await this.searchAll({ pagination: pagination, q: this.q, isMatched: isMatched })
      } else {
        this.showSearch = false
        await this.fetchAll({ pagination, isMatched: isMatched })
      }
    } catch (error) {
      this.$notify({ type: 'error', title: error.statusCode, text: error.message })
    } finally {
      this.searching = false
      this.loading = false
      this.clearing = false
    }
  }

  initPage() {
    this.q = ''
    if(typeof this.$route.query.q == 'string') {
      this.q = this.$route.query.q ? this.$route.query.q : ''
    }
    if(typeof this.$route.query.status == 'string') {
      this.filter.status = this.$route.query.status;
    }
    if(typeof this.$route.query.page == 'string') {
      this.currentPage = parseInt(this.$route.query.page)
    }

    this.fetchObjects()
  }

  search() {
    this.searching = true
    this.currentPage = 1
    // let query = {query: { q: this.q }}
    let query: any = Object.assign({}, this.$route.query, { 
      q: this.q,
      status: this.filter.status ? this.filter.status : undefined
    })
    this.$router.push({query})
    this.$nextTick(() => this.fetchObjects())
  }

  clearSearch() {
    this.clearing = true
    this.cancelSearch()
  }

  cancelSearch() {
    this.q = this.doneQ = null
    let query: any = Object.assign({}, this.$route.query, { q: undefined })
    this.$router.push({query})
    this.changePage(1)
  }

  submitQuery(query: string) {
    if(query && query.length > 0) {
      this.dfunc()
    } else {
      this.search()
    }
  }
  changeQuery(query: string) {
		this.search()
  }
  dfunc = debounce(this.changeQuery, 500)

  changePage(newPage: number) {
    this.currentPage = newPage
    this.$nextTick(() => this.fetchObjects())
  }

  async submitMatch(consumer: RedProduct, provider: ShopifyProduct) {
    await this.match({ consumerComponentId: consumer.id, providerComponentId: provider.id, providerType: 'shopify' })
    this.$notify({
      // title: 'Important message',
      text: 'Success!'
    });
  }

  async removeMatching(consumer: RedProduct) {
    this.$confirm.show({
      type: 'warning',
      buttonText: "Confirm",
      title: "Remove matching",
      text: "Your Red product will not be bind anymore with your Shopify product",
      onConfirm: () => {
        this.unmatch({ consumerComponentId: consumer.id, providerType: 'shopify' }).then(result => {
          this.$notify({
            // title: 'Important message',
            text: 'Success!'
          });
        }).catch(err => {
          this.$notify({
            type: 'error', text: err.message
          })
        }).finally(() => {
          this.$confirm.hide()
        })
      }
    });
  }
  
  confirmSynchronization() {
    this.$confirm.show({
      buttonText: "Synchronize",
      title: "Products synchronization",
      text: "The Red products are automatically synchronized every hour, but you can synchronized them manually.",
      onConfirm: () => {
        this.synchronizeProducts().then(result => {
          this.$notify({
            type: 'success', text: `Products synchronized`
          })
        }).catch(err => {
          this.$notify({
            type: 'error', text: err.message
          })
        }).finally(() => {
          this.initPage()
          this.$confirm.hide()
        })
      }
    });
  }

  // @Watch('$route.query') onRouteChanged() {
  //   this.$nextTick(() => {
  //     this.initPage()
  //   })
  // }
}
