








































































import { IPagination } from '@/types/interfaces';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import { Action } from 'vuex-class';
import ShopifyProduct from '@/models/ShopifyProduct';

@Component({

})

export default class SearchProduct extends Vue {
  private searching: Boolean = false
  private pageSize: number = 5
  private currentPage: number = 1
  private q: string = ''
  private results: ShopifyProduct[] = []
  private count: number = 0
  private isOpen: Boolean = false

  @Prop() public initQuery?: string;
  @Prop() public label?: string;
  @Prop() public title?: string;
  @Prop() public options?: string;
  @Prop() public image?: string;
  @Prop() public sku?: string;

  @Action('searchAll', { namespace: 'shopifyProducts' }) searchAll: any;

  @Watch('initQuery') onQueryChange() {
    if(this.initQuery) {
      this.q = this.initQuery
    }
  }

  async mounted() {
    if(this.initQuery) {
      this.q = this.initQuery  
    }
  }

  fetchObjects() {
    let pagination:IPagination = { page: this.currentPage, pageSize: this.pageSize }
    this.searchAll({ pagination: pagination, q: this.q, isMatched: false }).then(({items, count}: { items: ShopifyProduct[], count: number }) => {
      this.results = items
      this.count = count
      this.searching = false
    })
  }

  search() {
    if(this.q && this.q.length > 0) {
      this.isOpen = true
      this.currentPage = 1
      this.searching = true
      this.$nextTick(() => this.fetchObjects())
    }
  }

  cancelSearch() {
    this.isOpen = false
    this.q = ''
    this.results = []
    this.count = 0
  }

  changePage(newPage: number) {
    this.currentPage = newPage
    this.$nextTick(() => this.fetchObjects())
  }

  select(product: ShopifyProduct) {
    this.$emit('onSelect', product)
    this.cancelSearch()
  }

  outside() {
    if(this.isOpen) { 
      this.cancelSearch();
    }
  }

}
